<template>
  <div
    class="page_content page_content_index"
  >
    <section class="content">
      <div class="container">
        <div class="row">
          <div
            v-for="card in section_data"
            :key="card.name"
            class="col-6 col-md-4"
          >
            <router-link
              :to="card.link"
              class="card border-0"
            >
              <div class="card_body">
                <div class="card_img d-flex justify-content-center align-items-center">
                  <img
                    :src="card.img_url"
                    alt=""
                    class="img-fluid"
                  >
                </div>
                <span class="d-block text-center">{{ card.name }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      section_data: [
        // {
        //   img_url: require('@/assets/images/index/briefcase.svg'),
        //   name: '供應商管理',
        //   link: '/dashboard/supplier',
        // },
        {
          img_url: require('@/assets/images/index/briefcase.svg'),
          name: '最消消息管理',
          link: '/dashboard/news',
        },
        {
          img_url: require('@/assets/images/index/grid.svg'),
          name: '品類管理',
          link: '/dashboard/category',
        },
        {
          img_url: require('@/assets/images/index/setting.svg'),
          name: '商品管理',
          link: '/dashboard/product',
        },
        {
          img_url: require('@/assets/images/index/layout.svg'),
          name: '行銷版位管理',
          link: '/dashboard/indexboard',
        },
        {
          img_url: require('@/assets/images/index/volume.svg'),
          name: '行銷活動設定',
          link: '/dashboard/saleevent',
        },
        {
          img_url: require('@/assets/images/index/bar-chart.svg'),
          name: '業績管理',
          link: '/dashboard/performance',
        },
        {
          img_url: require('@/assets/images/index/file.svg'),
          name: ' 訂單管理',
          link: '/dashboard/orders',
        },
        {
          img_url: require('@/assets/images/index/user.svg'),
          name: '會員管理',
          link: '/dashboard/member',
        },
        {
          img_url: require('@/assets/images/index/package.svg'),
          name: '庫存管理',
          link: '/dashboard/stock',
        },
        {
          img_url: require('@/assets/images/index/shop.svg'),
          name: '門市管理',
          link: '/dashboard/store',
        },
        {
          img_url: require('@/assets/images/index/setting.svg'),
          name: '系統管理',
          link: '/dashboard/system',
        },
        {
          img_url: require('@/assets/images/index/code.svg'),
          name: '優惠碼管理',
          link: '/dashboard/couponcode',
        },
        {
          img_url: require('@/assets/images/index/statistics.svg'),
          name: '統計管理',
          link: '/dashboard/statistics',
        },
      ],
    };
  },
};
</script>
